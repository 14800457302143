var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { ProductCategoryList as BaseProductCategoryList } from "./ProductCategoryList.base";
import { generateImageLink } from "@bundles/UIAppBundle/enums/Images";
import styles from "../components/List/ProductCategoriesList.module.scss";
let ProductCategoryList = class ProductCategoryList extends BaseProductCategoryList {
    build() {
        super.build();
        this.remove("description");
        this.remove("image");
        this.remove("discountPercentage");
        this.remove("discountActiveUntil");
        this.remove("categoriesBreadcrumbs");
        this.remove("tags");
        this.remove("parent");
        this.remove("vatRate");
        this.remove("createdBy");
        this.remove("updatedBy");
        this.remove("updatedAt");
        this.remove("updatedAt");
        this.remove("updatedAt");
        this.remove("updatedAt");
        this.remove("products");
        this.remove("isRoot");
        this.remove("subcategories");
        this.remove("shippingRate.currency");
        this.remove("isRoot");
        this.remove("shippingRate.gross");
        this.remove("shippingRate.net");
        this.remove("shippingRate.tax");
        this.remove("customVatRate.name");
        this.remove("customVatRate.percentage");
        this.remove("customVatRate.description");
        this.remove("isRoot");
        this.remove("attributeOptions");
        this.remove("code");
        this.remove("salesRayParentId");
        this.remove("fullAttributeOptions");
        // Perform additional modifications such as updating how a list item renders or add additional ones
        this.update("name", {
            order: 1,
            render: (value, document) => {
                var _a;
                return (_jsxs("div", Object.assign({ className: `cc--text-ellipsis ${styles["black-bold"]}` }, { children: [_jsx("img", { src: generateImageLink((_a = document.image) === null || _a === void 0 ? void 0 : _a.downloadUrl) }, void 0), value] }), void 0));
            },
        });
        this.update("productsCount", {
            order: 2,
            render: (value) => _jsx("span", Object.assign({ className: styles["gray-bold"] }, { children: value }), void 0),
        });
        this.update("subCategoriesCount", {
            order: 3,
            render: (value) => _jsx("span", Object.assign({ className: styles["gray-bold"] }, { children: value }), void 0),
        });
        this.update("salesRayId", {});
        this.update("params", {
            sorter: false,
            render: (value) => (_jsx("div", Object.assign({ className: styles["params"] }, { children: (value !== null && value !== void 0 ? value : []).map((p) => p.name).join(", ") }), void 0)),
        });
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return {
            ...super.getRequestBody(),
            subcategories: {
                _id: 1,
                name: 1,
                params: 1,
                attributeOptions: {
                    name: 1,
                },
                fullAttributeOptions: {
                    name: 1,
                },
                productsCount: 1,
                subCategoriesCount: 1,
                subcategories: {
                    _id: 1,
                    name: 1,
                    attributeOptions: {
                        name: 1,
                    },
                    fullAttributeOptions: {
                        name: 1,
                    },
                    productsCount: 1,
                    subCategoriesCount: 1,
                    subcategories: {
                        _id: 1,
                        name: 1,
                        fullAttributeOptions: {
                            name: 1,
                        },
                        productsCount: 1,
                        subCategoriesCount: 1,
                    },
                },
            },
        };
    }
};
ProductCategoryList = __decorate([
    Service({ transient: true })
], ProductCategoryList);
export { ProductCategoryList };

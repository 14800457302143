var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { EndUserProfilesCollection, InvoicesCollection, PaymentsCollection, InternalUserProfilesCollection, PaymentOptionsCollection, ShippingOptionsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let OrderListFiltersForm = class OrderListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "orderNumber",
                label: t("management.orders.fields.orderNumber"),
                name: ["orderNumber"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "placedAt",
                label: t("management.orders.fields.placedAt"),
                name: ["placedAt"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "status",
                label: t("management.orders.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.orders.fields.status") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "DRAFT" }, { children: "Draft" }), "DRAFT"), _jsx(Ant.Select.Option, Object.assign({ value: "PLACED" }, { children: "Placed" }), "PLACED"), _jsx(Ant.Select.Option, Object.assign({ value: "SHIPPING" }, { children: "Shipping" }), "SHIPPING"), _jsx(Ant.Select.Option, Object.assign({ value: "DELIVERED" }, { children: "Delivered" }), "DELIVERED"), _jsx(Ant.Select.Option, Object.assign({ value: "CANCELED" }, { children: "Canceled" }), "CANCELED")] }), void 0) }), void 0)),
            },
            {
                id: "billingAddress",
                label: t("management.orders.fields.billingAddress"),
                name: ["billingAddress"],
                columns: true,
                nest: [
                    {
                        id: "nbh",
                        label: t("management.orders.fields.billingAddress.nbh"),
                        name: ["billingAddress", "nbh"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_floor",
                        label: t("management.orders.fields.billingAddress.building_floor"),
                        name: ["billingAddress", "building_floor"],
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.orders.fields.billingAddress.zip"),
                        name: ["billingAddress", "zip"],
                        component: Ant.Input,
                    },
                    {
                        id: "address",
                        label: t("management.orders.fields.billingAddress.address"),
                        name: ["billingAddress", "address"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_entrance",
                        label: t("management.orders.fields.billingAddress.building_entrance"),
                        name: ["billingAddress", "building_entrance"],
                        component: Ant.Input,
                    },
                    {
                        id: "street",
                        label: t("management.orders.fields.billingAddress.street"),
                        name: ["billingAddress", "street"],
                        component: Ant.Input,
                    },
                    {
                        id: "district",
                        label: t("management.orders.fields.billingAddress.district"),
                        name: ["billingAddress", "district"],
                        component: Ant.Input,
                    },
                    {
                        id: "tax",
                        label: t("management.orders.fields.billingAddress.tax"),
                        name: ["billingAddress", "tax"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.orders.fields.billingAddress.country"),
                        name: ["billingAddress", "country"],
                        component: Ant.Input,
                    },
                    {
                        id: "note",
                        label: t("management.orders.fields.billingAddress.note"),
                        name: ["billingAddress", "note"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.orders.fields.billingAddress.email"),
                        name: ["billingAddress", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "num",
                        label: t("management.orders.fields.billingAddress.num"),
                        name: ["billingAddress", "num"],
                        component: Ant.Input,
                    },
                    {
                        id: "salesRayId",
                        label: t("management.orders.fields.billingAddress.salesRayId"),
                        name: ["billingAddress", "salesRayId"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "zip_num",
                        label: t("management.orders.fields.billingAddress.zip_num"),
                        name: ["billingAddress", "zip_num"],
                        component: Ant.Input,
                    },
                    {
                        id: "fax",
                        label: t("management.orders.fields.billingAddress.fax"),
                        name: ["billingAddress", "fax"],
                        component: Ant.Input,
                    },
                    {
                        id: "building",
                        label: t("management.orders.fields.billingAddress.building"),
                        name: ["billingAddress", "building"],
                        component: Ant.Input,
                    },
                    {
                        id: "settlement",
                        label: t("management.orders.fields.billingAddress.settlement"),
                        name: ["billingAddress", "settlement"],
                        component: Ant.Input,
                    },
                    {
                        id: "phone",
                        label: t("management.orders.fields.billingAddress.phone"),
                        name: ["billingAddress", "phone"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_apartment",
                        label: t("management.orders.fields.billingAddress.building_apartment"),
                        name: ["billingAddress", "building_apartment"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "salesRayId",
                label: t("management.orders.fields.salesRayId"),
                name: ["salesRayId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "shippingAddress",
                label: t("management.orders.fields.shippingAddress"),
                name: ["shippingAddress"],
                columns: true,
                nest: [
                    {
                        id: "nbh",
                        label: t("management.orders.fields.shippingAddress.nbh"),
                        name: ["shippingAddress", "nbh"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_floor",
                        label: t("management.orders.fields.shippingAddress.building_floor"),
                        name: ["shippingAddress", "building_floor"],
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.orders.fields.shippingAddress.zip"),
                        name: ["shippingAddress", "zip"],
                        component: Ant.Input,
                    },
                    {
                        id: "address",
                        label: t("management.orders.fields.shippingAddress.address"),
                        name: ["shippingAddress", "address"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_entrance",
                        label: t("management.orders.fields.shippingAddress.building_entrance"),
                        name: ["shippingAddress", "building_entrance"],
                        component: Ant.Input,
                    },
                    {
                        id: "street",
                        label: t("management.orders.fields.shippingAddress.street"),
                        name: ["shippingAddress", "street"],
                        component: Ant.Input,
                    },
                    {
                        id: "district",
                        label: t("management.orders.fields.shippingAddress.district"),
                        name: ["shippingAddress", "district"],
                        component: Ant.Input,
                    },
                    {
                        id: "tax",
                        label: t("management.orders.fields.shippingAddress.tax"),
                        name: ["shippingAddress", "tax"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.orders.fields.shippingAddress.country"),
                        name: ["shippingAddress", "country"],
                        component: Ant.Input,
                    },
                    {
                        id: "note",
                        label: t("management.orders.fields.shippingAddress.note"),
                        name: ["shippingAddress", "note"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.orders.fields.shippingAddress.email"),
                        name: ["shippingAddress", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "num",
                        label: t("management.orders.fields.shippingAddress.num"),
                        name: ["shippingAddress", "num"],
                        component: Ant.Input,
                    },
                    {
                        id: "salesRayId",
                        label: t("management.orders.fields.shippingAddress.salesRayId"),
                        name: ["shippingAddress", "salesRayId"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "zip_num",
                        label: t("management.orders.fields.shippingAddress.zip_num"),
                        name: ["shippingAddress", "zip_num"],
                        component: Ant.Input,
                    },
                    {
                        id: "fax",
                        label: t("management.orders.fields.shippingAddress.fax"),
                        name: ["shippingAddress", "fax"],
                        component: Ant.Input,
                    },
                    {
                        id: "building",
                        label: t("management.orders.fields.shippingAddress.building"),
                        name: ["shippingAddress", "building"],
                        component: Ant.Input,
                    },
                    {
                        id: "settlement",
                        label: t("management.orders.fields.shippingAddress.settlement"),
                        name: ["shippingAddress", "settlement"],
                        component: Ant.Input,
                    },
                    {
                        id: "phone",
                        label: t("management.orders.fields.shippingAddress.phone"),
                        name: ["shippingAddress", "phone"],
                        component: Ant.Input,
                    },
                    {
                        id: "building_apartment",
                        label: t("management.orders.fields.shippingAddress.building_apartment"),
                        name: ["shippingAddress", "building_apartment"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "buyerSnapshot",
                label: t("management.orders.fields.buyerSnapshot"),
                name: ["buyerSnapshot"],
                columns: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.orders.fields.buyerSnapshot.name"),
                        name: ["buyerSnapshot", "name"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "phoneNumber",
                        label: t("management.orders.fields.buyerSnapshot.phoneNumber"),
                        name: ["buyerSnapshot", "phoneNumber"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.orders.fields.buyerSnapshot.email"),
                        name: ["buyerSnapshot", "email"],
                        required: true,
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "privateNotes",
                label: t("management.orders.fields.privateNotes"),
                name: ["privateNotes"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "finalPrice",
                label: t("management.orders.fields.finalPrice"),
                name: ["finalPrice"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "couponsOnHold",
                label: t("management.orders.fields.couponsOnHold"),
                name: ["couponsOnHold"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "appliedCoupons",
                label: t("management.orders.fields.appliedCoupons"),
                name: ["appliedCoupons"],
                columns: true,
                nest: [
                    {
                        id: "couponCode",
                        label: t("management.orders.fields.appliedCoupons.couponCode"),
                        name: ["appliedCoupons", "couponCode"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "couponId",
                        label: t("management.orders.fields.appliedCoupons.couponId"),
                        name: ["appliedCoupons", "couponId"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "amount",
                        label: t("management.orders.fields.appliedCoupons.amount"),
                        name: ["appliedCoupons", "amount"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "isPercentage",
                        label: t("management.orders.fields.appliedCoupons.isPercentage"),
                        name: ["appliedCoupons", "isPercentage"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.orders.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.orders.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.orders.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.orders.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "buyerId",
                label: t("management.orders.fields.buyer"),
                name: ["buyerId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUserProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "invoiceId",
                label: t("management.orders.fields.invoice"),
                name: ["invoiceId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: InvoicesCollection, field: "invoiceId", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "paymentId",
                label: t("management.orders.fields.payment"),
                name: ["paymentId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: PaymentsCollection, field: "paymentProcessor", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "salesAgentId",
                label: t("management.orders.fields.salesAgent"),
                name: ["salesAgentId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: InternalUserProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "paymentOptionId",
                label: t("management.orders.fields.paymentOption"),
                name: ["paymentOptionId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: PaymentOptionsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "shippingOptionId",
                label: t("management.orders.fields.shippingOption"),
                name: ["shippingOptionId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ShippingOptionsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "createdById",
                label: t("management.orders.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.orders.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "updatedById",
                label: t("management.orders.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.orders.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
OrderListFiltersForm = __decorate([
    Service({ transient: true })
], OrderListFiltersForm);
export { OrderListFiltersForm };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { UsersCollection, ProductsCollection, InternalUserProfilesCollection, EndUserProfilesCollection, } from "@bundles/UIAppBundle/collections";
let EndUserProfileCreateForm = class EndUserProfileCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "billingAddress",
                label: t("management.end_user_profiles.fields.billingAddress"),
                name: ["billingAddress"],
                nest: [
                    {
                        id: "salesRayId",
                        label: t("management.end_user_profiles.fields.billingAddress.salesRayId"),
                        name: ["billingAddress", "salesRayId"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "firstName",
                        label: t("management.end_user_profiles.fields.billingAddress.firstName"),
                        name: ["billingAddress", "firstName"],
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.end_user_profiles.fields.billingAddress.lastName"),
                        name: ["billingAddress", "lastName"],
                        component: Ant.Input,
                    },
                    {
                        id: "phoneNumber",
                        label: t("management.end_user_profiles.fields.billingAddress.phoneNumber"),
                        name: ["billingAddress", "phoneNumber"],
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine1",
                        label: t("management.end_user_profiles.fields.billingAddress.streetAddressLine1"),
                        name: ["billingAddress", "streetAddressLine1"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine2",
                        label: t("management.end_user_profiles.fields.billingAddress.streetAddressLine2"),
                        name: ["billingAddress", "streetAddressLine2"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.end_user_profiles.fields.billingAddress.email"),
                        name: ["billingAddress", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "city",
                        label: t("management.end_user_profiles.fields.billingAddress.city"),
                        name: ["billingAddress", "city"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.end_user_profiles.fields.billingAddress.town"),
                        name: ["billingAddress", "town"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.end_user_profiles.fields.billingAddress.country"),
                        name: ["billingAddress", "country"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "zipcode",
                        label: t("management.end_user_profiles.fields.billingAddress.zipcode"),
                        name: ["billingAddress", "zipcode"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "companyName",
                        label: t("management.end_user_profiles.fields.billingAddress.companyName"),
                        name: ["billingAddress", "companyName"],
                        component: Ant.Input,
                    },
                    {
                        id: "vatNumber",
                        label: t("management.end_user_profiles.fields.billingAddress.vatNumber"),
                        name: ["billingAddress", "vatNumber"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "firstName",
                label: t("management.end_user_profiles.fields.firstName"),
                name: ["firstName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "middleName",
                label: t("management.end_user_profiles.fields.middleName"),
                name: ["middleName"],
                component: Ant.Input,
            },
            {
                id: "lastName",
                label: t("management.end_user_profiles.fields.lastName"),
                name: ["lastName"],
                component: Ant.Input,
            },
            {
                id: "companyName",
                label: t("management.end_user_profiles.fields.companyName"),
                name: ["companyName"],
                component: Ant.Input,
            },
            {
                id: "vatNumber",
                label: t("management.end_user_profiles.fields.vatNumber"),
                name: ["vatNumber"],
                component: Ant.Input,
            },
            {
                id: "salesRayId",
                label: t("management.end_user_profiles.fields.salesRayId"),
                name: ["salesRayId"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "birthDate",
                label: t("management.end_user_profiles.fields.birthDate"),
                name: ["birthDate"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
            },
            {
                id: "email",
                label: t("management.end_user_profiles.fields.email"),
                name: ["email"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "phoneNumber",
                label: t("management.end_user_profiles.fields.phoneNumber"),
                name: ["phoneNumber"],
                component: Ant.Input,
            },
            {
                id: "isAnonymousCheckout",
                label: t("management.end_user_profiles.fields.isAnonymousCheckout"),
                name: ["isAnonymousCheckout"],
                tooltip: t("management.end_user_profiles.fields.isAnonymousCheckout_description"),
                initialValue: false,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "language",
                label: t("management.end_user_profiles.fields.language"),
                name: ["language"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "en", label: "En" },
                        { value: "ro", label: "Ro" },
                        { value: "de", label: "De" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.end_user_profiles.fields.language") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.end_user_profiles.fields.language_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.end_user_profiles.fields.language_enums.${option.label.toLowerCase()}`)
                                    : option.label }), option.value))) }), void 0) }), void 0));
                },
            },
            {
                id: "salesRayDefaultPricelistId",
                label: t("management.end_user_profiles.fields.salesRayDefaultPricelistId"),
                name: ["salesRayDefaultPricelistId"],
                component: Ant.InputNumber,
            },
            {
                id: "userId",
                label: t("management.end_user_profiles.fields.user"),
                name: ["userId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", required: false }, void 0) }), void 0)),
            },
            {
                id: "latestViewedProductsIds",
                label: t("management.end_user_profiles.fields.latestViewedProducts"),
                name: ["latestViewedProductsIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductsCollection, field: "name", required: false, mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "adminId",
                label: t("management.end_user_profiles.fields.admin"),
                name: ["adminId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: InternalUserProfilesCollection, field: "fullName", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.end_user_profiles.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.END_USER_PROFILES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.END_USER_PROFILES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.END_USER_PROFILES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => EndUserProfilesCollection),
    __metadata("design:type", typeof (_a = typeof EndUserProfilesCollection !== "undefined" && EndUserProfilesCollection) === "function" ? _a : Object)
], EndUserProfileCreateForm.prototype, "collection", void 0);
EndUserProfileCreateForm = __decorate([
    Service({ transient: true })
], EndUserProfileCreateForm);
export { EndUserProfileCreateForm };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, ProductsCollection, InternalUserProfilesCollection, } from "@bundles/UIAppBundle/collections";
let EndUserProfileListFiltersForm = class EndUserProfileListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "billingAddress",
                label: t("management.end_user_profiles.fields.billingAddress"),
                name: ["billingAddress"],
                columns: true,
                nest: [
                    {
                        id: "salesRayId",
                        label: t("management.end_user_profiles.fields.billingAddress.salesRayId"),
                        name: ["billingAddress", "salesRayId"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "firstName",
                        label: t("management.end_user_profiles.fields.billingAddress.firstName"),
                        name: ["billingAddress", "firstName"],
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.end_user_profiles.fields.billingAddress.lastName"),
                        name: ["billingAddress", "lastName"],
                        component: Ant.Input,
                    },
                    {
                        id: "phoneNumber",
                        label: t("management.end_user_profiles.fields.billingAddress.phoneNumber"),
                        name: ["billingAddress", "phoneNumber"],
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine1",
                        label: t("management.end_user_profiles.fields.billingAddress.streetAddressLine1"),
                        name: ["billingAddress", "streetAddressLine1"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "streetAddressLine2",
                        label: t("management.end_user_profiles.fields.billingAddress.streetAddressLine2"),
                        name: ["billingAddress", "streetAddressLine2"],
                        component: Ant.Input,
                    },
                    {
                        id: "email",
                        label: t("management.end_user_profiles.fields.billingAddress.email"),
                        name: ["billingAddress", "email"],
                        component: Ant.Input,
                    },
                    {
                        id: "city",
                        label: t("management.end_user_profiles.fields.billingAddress.city"),
                        name: ["billingAddress", "city"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "town",
                        label: t("management.end_user_profiles.fields.billingAddress.town"),
                        name: ["billingAddress", "town"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.end_user_profiles.fields.billingAddress.country"),
                        name: ["billingAddress", "country"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "zipcode",
                        label: t("management.end_user_profiles.fields.billingAddress.zipcode"),
                        name: ["billingAddress", "zipcode"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "companyName",
                        label: t("management.end_user_profiles.fields.billingAddress.companyName"),
                        name: ["billingAddress", "companyName"],
                        component: Ant.Input,
                    },
                    {
                        id: "vatNumber",
                        label: t("management.end_user_profiles.fields.billingAddress.vatNumber"),
                        name: ["billingAddress", "vatNumber"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "firstName",
                label: t("management.end_user_profiles.fields.firstName"),
                name: ["firstName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "middleName",
                label: t("management.end_user_profiles.fields.middleName"),
                name: ["middleName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "lastName",
                label: t("management.end_user_profiles.fields.lastName"),
                name: ["lastName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "companyName",
                label: t("management.end_user_profiles.fields.companyName"),
                name: ["companyName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "vatNumber",
                label: t("management.end_user_profiles.fields.vatNumber"),
                name: ["vatNumber"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "salesRayId",
                label: t("management.end_user_profiles.fields.salesRayId"),
                name: ["salesRayId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "birthDate",
                label: t("management.end_user_profiles.fields.birthDate"),
                name: ["birthDate"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "email",
                label: t("management.end_user_profiles.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "phoneNumber",
                label: t("management.end_user_profiles.fields.phoneNumber"),
                name: ["phoneNumber"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "isAnonymousCheckout",
                label: t("management.end_user_profiles.fields.isAnonymousCheckout"),
                name: ["isAnonymousCheckout"],
                tooltip: t("management.end_user_profiles.fields.isAnonymousCheckout_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "language",
                label: t("management.end_user_profiles.fields.language"),
                name: ["language"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.end_user_profiles.fields.language") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "en" }, { children: "En" }), "en"), _jsx(Ant.Select.Option, Object.assign({ value: "ro" }, { children: "Ro" }), "ro"), _jsx(Ant.Select.Option, Object.assign({ value: "de" }, { children: "De" }), "de")] }), void 0) }), void 0)),
            },
            {
                id: "salesRayDefaultPricelistId",
                label: t("management.end_user_profiles.fields.salesRayDefaultPricelistId"),
                name: ["salesRayDefaultPricelistId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.end_user_profiles.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.end_user_profiles.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.end_user_profiles.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.end_user_profiles.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "userId",
                label: t("management.end_user_profiles.fields.user"),
                name: ["userId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "latestViewedProductsIds",
                label: t("management.end_user_profiles.fields.latestViewedProducts"),
                name: ["latestViewedProductsIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "adminId",
                label: t("management.end_user_profiles.fields.admin"),
                name: ["adminId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: InternalUserProfilesCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "createdById",
                label: t("management.end_user_profiles.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.end_user_profiles.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "updatedById",
                label: t("management.end_user_profiles.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.end_user_profiles.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
EndUserProfileListFiltersForm = __decorate([
    Service({ transient: true })
], EndUserProfileListFiltersForm);
export { EndUserProfileListFiltersForm };

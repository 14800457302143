var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let OrderViewer = class OrderViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.orders.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "orderNumber",
                label: t("management.orders.fields.orderNumber"),
                dataIndex: ["orderNumber"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "placedAt",
                label: t("management.orders.fields.placedAt"),
                dataIndex: ["placedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "status",
                label: t("management.orders.fields.status"),
                dataIndex: ["status"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.nbh",
                label: t("management.orders.fields.billingAddress.nbh"),
                dataIndex: ["billingAddress", "nbh"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_floor",
                label: t("management.orders.fields.billingAddress.building_floor"),
                dataIndex: ["billingAddress", "building_floor"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.zip",
                label: t("management.orders.fields.billingAddress.zip"),
                dataIndex: ["billingAddress", "zip"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.address",
                label: t("management.orders.fields.billingAddress.address"),
                dataIndex: ["billingAddress", "address"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_entrance",
                label: t("management.orders.fields.billingAddress.building_entrance"),
                dataIndex: ["billingAddress", "building_entrance"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.street",
                label: t("management.orders.fields.billingAddress.street"),
                dataIndex: ["billingAddress", "street"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.district",
                label: t("management.orders.fields.billingAddress.district"),
                dataIndex: ["billingAddress", "district"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.tax",
                label: t("management.orders.fields.billingAddress.tax"),
                dataIndex: ["billingAddress", "tax"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.country",
                label: t("management.orders.fields.billingAddress.country"),
                dataIndex: ["billingAddress", "country"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.note",
                label: t("management.orders.fields.billingAddress.note"),
                dataIndex: ["billingAddress", "note"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.email",
                label: t("management.orders.fields.billingAddress.email"),
                dataIndex: ["billingAddress", "email"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.num",
                label: t("management.orders.fields.billingAddress.num"),
                dataIndex: ["billingAddress", "num"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.salesRayId",
                label: t("management.orders.fields.billingAddress.salesRayId"),
                dataIndex: ["billingAddress", "salesRayId"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.zip_num",
                label: t("management.orders.fields.billingAddress.zip_num"),
                dataIndex: ["billingAddress", "zip_num"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.fax",
                label: t("management.orders.fields.billingAddress.fax"),
                dataIndex: ["billingAddress", "fax"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building",
                label: t("management.orders.fields.billingAddress.building"),
                dataIndex: ["billingAddress", "building"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.settlement",
                label: t("management.orders.fields.billingAddress.settlement"),
                dataIndex: ["billingAddress", "settlement"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.phone",
                label: t("management.orders.fields.billingAddress.phone"),
                dataIndex: ["billingAddress", "phone"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "billingAddress.building_apartment",
                label: t("management.orders.fields.billingAddress.building_apartment"),
                dataIndex: ["billingAddress", "building_apartment"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "salesRayId",
                label: t("management.orders.fields.salesRayId"),
                dataIndex: ["salesRayId"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.nbh",
                label: t("management.orders.fields.shippingAddress.nbh"),
                dataIndex: ["shippingAddress", "nbh"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_floor",
                label: t("management.orders.fields.shippingAddress.building_floor"),
                dataIndex: ["shippingAddress", "building_floor"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.zip",
                label: t("management.orders.fields.shippingAddress.zip"),
                dataIndex: ["shippingAddress", "zip"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.address",
                label: t("management.orders.fields.shippingAddress.address"),
                dataIndex: ["shippingAddress", "address"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_entrance",
                label: t("management.orders.fields.shippingAddress.building_entrance"),
                dataIndex: ["shippingAddress", "building_entrance"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.street",
                label: t("management.orders.fields.shippingAddress.street"),
                dataIndex: ["shippingAddress", "street"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.district",
                label: t("management.orders.fields.shippingAddress.district"),
                dataIndex: ["shippingAddress", "district"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.tax",
                label: t("management.orders.fields.shippingAddress.tax"),
                dataIndex: ["shippingAddress", "tax"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.country",
                label: t("management.orders.fields.shippingAddress.country"),
                dataIndex: ["shippingAddress", "country"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.note",
                label: t("management.orders.fields.shippingAddress.note"),
                dataIndex: ["shippingAddress", "note"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.email",
                label: t("management.orders.fields.shippingAddress.email"),
                dataIndex: ["shippingAddress", "email"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.num",
                label: t("management.orders.fields.shippingAddress.num"),
                dataIndex: ["shippingAddress", "num"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.salesRayId",
                label: t("management.orders.fields.shippingAddress.salesRayId"),
                dataIndex: ["shippingAddress", "salesRayId"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.zip_num",
                label: t("management.orders.fields.shippingAddress.zip_num"),
                dataIndex: ["shippingAddress", "zip_num"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.fax",
                label: t("management.orders.fields.shippingAddress.fax"),
                dataIndex: ["shippingAddress", "fax"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building",
                label: t("management.orders.fields.shippingAddress.building"),
                dataIndex: ["shippingAddress", "building"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.settlement",
                label: t("management.orders.fields.shippingAddress.settlement"),
                dataIndex: ["shippingAddress", "settlement"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.phone",
                label: t("management.orders.fields.shippingAddress.phone"),
                dataIndex: ["shippingAddress", "phone"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingAddress.building_apartment",
                label: t("management.orders.fields.shippingAddress.building_apartment"),
                dataIndex: ["shippingAddress", "building_apartment"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "buyerSnapshot.name",
                label: t("management.orders.fields.buyerSnapshot.name"),
                dataIndex: ["buyerSnapshot", "name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "buyerSnapshot.phoneNumber",
                label: t("management.orders.fields.buyerSnapshot.phoneNumber"),
                dataIndex: ["buyerSnapshot", "phoneNumber"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "buyerSnapshot.email",
                label: t("management.orders.fields.buyerSnapshot.email"),
                dataIndex: ["buyerSnapshot", "email"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "privateNotes",
                label: t("management.orders.fields.privateNotes"),
                dataIndex: ["privateNotes"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price",
                label: t("management.orders.fields.price"),
                dataIndex: ["price"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceVAT",
                label: t("management.orders.fields.priceVAT"),
                dataIndex: ["priceVAT"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "priceTotal",
                label: t("management.orders.fields.priceTotal"),
                dataIndex: ["priceTotal"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "finalPrice",
                label: t("management.orders.fields.finalPrice"),
                dataIndex: ["finalPrice"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "couponsOnHold",
                label: t("management.orders.fields.couponsOnHold"),
                dataIndex: ["couponsOnHold"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "coupons",
                label: t("management.orders.fields.coupons"),
                dataIndex: ["coupons"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "appliedCoupons",
                label: t("management.orders.fields.appliedCoupons"),
                dataIndex: ["appliedCoupons"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "createdAt",
                label: t("management.orders.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                label: t("management.orders.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "items",
                label: t("management.orders.fields.items"),
                dataIndex: ["items"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.ORDER_ITEMS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "productSnapshot.name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "buyer",
                label: t("management.orders.fields.buyer"),
                dataIndex: ["buyer"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.END_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "invoice",
                label: t("management.orders.fields.invoice"),
                dataIndex: ["invoice"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.INVOICES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "invoiceId",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "payment",
                label: t("management.orders.fields.payment"),
                dataIndex: ["payment"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PAYMENTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "paymentProcessor",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "salesAgent",
                label: t("management.orders.fields.salesAgent"),
                dataIndex: ["salesAgent"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.INTERNAL_USER_PROFILES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "paymentOption",
                label: t("management.orders.fields.paymentOption"),
                dataIndex: ["paymentOption"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PAYMENT_OPTIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "shippingOption",
                label: t("management.orders.fields.shippingOption"),
                dataIndex: ["shippingOption"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.SHIPPING_OPTIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdBy",
                label: t("management.orders.fields.createdBy"),
                dataIndex: ["createdBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedBy",
                label: t("management.orders.fields.updatedBy"),
                dataIndex: ["updatedBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            orderNumber: 1,
            placedAt: 1,
            status: 1,
            billingAddress: {
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                salesRayId: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
            salesRayId: 1,
            shippingAddress: {
                nbh: 1,
                building_floor: 1,
                zip: 1,
                address: 1,
                building_entrance: 1,
                street: 1,
                district: 1,
                tax: 1,
                country: 1,
                note: 1,
                email: 1,
                num: 1,
                salesRayId: 1,
                zip_num: 1,
                fax: 1,
                building: 1,
                settlement: 1,
                phone: 1,
                building_apartment: 1,
            },
            buyerSnapshot: {
                name: 1,
                phoneNumber: 1,
                email: 1,
            },
            privateNotes: 1,
            price: 1,
            priceVAT: 1,
            priceTotal: 1,
            finalPrice: 1,
            couponsOnHold: 1,
            coupons: {
                couponCode: 1,
                couponId: 1,
                amount: 1,
                isPercentage: 1,
            },
            appliedCoupons: {
                couponCode: 1,
                couponId: 1,
                amount: 1,
                isPercentage: 1,
            },
            createdAt: 1,
            updatedAt: 1,
            items: {
                _id: 1,
                productSnapshot: {
                    name: 1,
                },
            },
            buyer: {
                _id: 1,
                fullName: 1,
            },
            buyerId: 1,
            invoice: {
                _id: 1,
                invoiceId: 1,
            },
            invoiceId: 1,
            payment: {
                _id: 1,
                paymentProcessor: 1,
            },
            paymentId: 1,
            salesAgent: {
                _id: 1,
                fullName: 1,
            },
            salesAgentId: 1,
            paymentOption: {
                _id: 1,
                name: 1,
            },
            paymentOptionId: 1,
            shippingOption: {
                _id: 1,
                name: 1,
            },
            shippingOptionId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
OrderViewer = __decorate([
    Service({ transient: true })
], OrderViewer);
export { OrderViewer };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { ProductCategoriesCollection, VatRatesCollection, } from "@bundles/UIAppBundle/collections";
let ProductCategoryEditForm = class ProductCategoryEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.product_categories.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.product_categories.fields.description"),
                name: ["description"],
                component: Ant.Input,
            },
            {
                id: "attributeOptions",
                label: t("management.product_categories.fields.attributeOptions"),
                name: ["attributeOptions"],
                required: true,
                isList: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.product_categories.fields.attributeOptions.name"),
                        name: ["attributeOptions", "name"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "type",
                        label: t("management.product_categories.fields.attributeOptions.type"),
                        name: ["attributeOptions", "type"],
                        initialValue: [],
                        render: (props) => {
                            const options = [
                                { value: "string", label: "String" },
                                { value: "number", label: "Number" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: t("management.product_categories.fields.attributeOptions.type") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: t(`management.product_categories.fields.attributeOptions.type_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.product_categories.fields.attributeOptions.type_enums.${option.label.toLowerCase()}`)
                                            : option.label }), option.value))) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "isRequired",
                        label: t("management.product_categories.fields.attributeOptions.isRequired"),
                        name: ["attributeOptions", "isRequired"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "isEnum",
                        label: t("management.product_categories.fields.attributeOptions.isEnum"),
                        name: ["attributeOptions", "isEnum"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "enumValues",
                        label: t("management.product_categories.fields.attributeOptions.enumValues"),
                        name: ["attributeOptions", "enumValues"],
                        initialValue: [],
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "isRange",
                        label: t("management.product_categories.fields.attributeOptions.isRange"),
                        name: ["attributeOptions", "isRange"],
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "defaultValue",
                        label: t("management.product_categories.fields.attributeOptions.defaultValue"),
                        name: ["attributeOptions", "defaultValue"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "defaultSuffix",
                        label: t("management.product_categories.fields.attributeOptions.defaultSuffix"),
                        name: ["attributeOptions", "defaultSuffix"],
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "rangeMin",
                        label: t("management.product_categories.fields.attributeOptions.rangeMin"),
                        name: ["attributeOptions", "rangeMin"],
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "rangeMax",
                        label: t("management.product_categories.fields.attributeOptions.rangeMax"),
                        name: ["attributeOptions", "rangeMax"],
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                ],
                initialValue: [],
            },
            {
                id: "discountPercentage",
                label: t("management.product_categories.fields.discountPercentage"),
                name: ["discountPercentage"],
                component: Ant.InputNumber,
            },
            {
                id: "discountActiveUntil",
                label: t("management.product_categories.fields.discountActiveUntil"),
                name: ["discountActiveUntil"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: false }, void 0) }), void 0)),
            },
            {
                id: "tags",
                label: t("management.product_categories.fields.tags"),
                name: ["tags"],
                required: true,
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "isRoot",
                label: t("management.product_categories.fields.isRoot"),
                name: ["isRoot"],
                required: true,
                initialValue: false,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "salesRayId",
                label: t("management.product_categories.fields.salesRayId"),
                name: ["salesRayId"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "salesRayParentId",
                label: t("management.product_categories.fields.salesRayParentId"),
                name: ["salesRayParentId"],
                component: Ant.InputNumber,
            },
            {
                id: "code",
                label: t("management.product_categories.fields.code"),
                name: ["code"],
                component: Ant.Input,
            },
            {
                id: "params",
                label: t("management.product_categories.fields.params"),
                name: ["params"],
                nest: [],
            },
            {
                id: "parentId",
                label: t("management.product_categories.fields.parent"),
                name: ["parentId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductCategoriesCollection, field: "name", required: false }, void 0) }), void 0)),
            },
            {
                id: "vatRateId",
                label: t("management.product_categories.fields.vatRate"),
                name: ["vatRateId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: VatRatesCollection, field: "name", required: false }, void 0) }), void 0)),
            },
            {
                id: "imageId",
                label: t("management.product_categories.fields.image"),
                name: ["imageId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            description: 1,
            attributeOptions: {
                name: 1,
                type: 1,
                isRequired: 1,
                isEnum: 1,
                enumValues: 1,
                isRange: 1,
                defaultValue: 1,
                defaultSuffix: 1,
                rangeMin: 1,
                rangeMax: 1,
            },
            fullAttributeOptions: {
                name: 1,
                type: 1,
                isRequired: 1,
                isEnum: 1,
                enumValues: 1,
                isRange: 1,
                defaultValue: 1,
                defaultSuffix: 1,
                rangeMin: 1,
                rangeMax: 1,
            },
            discountPercentage: 1,
            discountActiveUntil: 1,
            categoriesBreadcrumbs: {
                name: 1,
                discountPercentage: 1,
                discountActiveUntil: 1,
                attributeOptions: {
                    name: 1,
                    type: 1,
                    isRequired: 1,
                    isEnum: 1,
                    enumValues: 1,
                    isRange: 1,
                    defaultValue: 1,
                    defaultSuffix: 1,
                    rangeMin: 1,
                    rangeMax: 1,
                },
            },
            tags: 1,
            isRoot: 1,
            salesRayId: 1,
            salesRayParentId: 1,
            code: 1,
            params: 1,
            parent: {
                _id: 1,
                name: 1,
            },
            parentId: 1,
            vatRate: {
                _id: 1,
                name: 1,
            },
            vatRateId: 1,
            image: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            imageId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.product_categories.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ProductCategoriesCollection),
    __metadata("design:type", typeof (_a = typeof ProductCategoriesCollection !== "undefined" && ProductCategoriesCollection) === "function" ? _a : Object)
], ProductCategoryEditForm.prototype, "collection", void 0);
ProductCategoryEditForm = __decorate([
    Service({ transient: true })
], ProductCategoryEditForm);
export { ProductCategoryEditForm };

import { gql } from "@apollo/client";
export const AdminGetCategories = gql `
  query AdminCategoriesGetAll($input: AdminCategoriesGetAllInput!) {
    AdminCategoriesGetAll(input: $input) {
      data {
        _id
        name
        salesRayId
        salesRayParentId
        attributeOptions {
          name
        }
        fullAttributeOptions {
          name
        }
        productsCount
        subCategoriesCount
        image {
          _id
          downloadUrl
        }
        params
      }
      skip
      limit
      total
    }
  }
`;
